import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from "react-router";
import { iso31661 } from "iso-3166";
import moment from 'moment';

interface OrderType {
  shopifyId: string;
  amountOfTests: number;
  finishedTests: number;
  level: string;
  hasBvdTest: boolean;
  status: string;
}

function Order(props:any) {

    const [order, setOrder] = useState<OrderType | null>(null);
    const [error, setError] = useState(false);
    const [tests, setTests] = useState<Array<{
    id: number;
    distributor: string;
    owner: string;
    uniqueAnimalId: string;
    sampleBarcodeId: string;
    sampleType: string;
    eartagId: string;
    breedOfSample: string;
    sex: string;
    countryCode: string;
    multipleBirth: number;
    birthDate: string;
    sireRegistration: string;
    breedOfSire: string;
    damRegistrationId: string;
    breedOfDam: string;
    isProductionBull: boolean;
    serviceLevel: string;
    hasBvdTest: boolean;
    comments: string;
    }>>([]);
    const [view, setView] = useState('overview');
    const [availableTests, setAvailableTests] = useState(0);

    const { orderId } = useParams();
    let [token] = useSearchParams();

    useEffect(() => {
        if (order === null) {
        fetch(`https://app.genfarmeval.com/api/orders/${ orderId }?${ token }`)
            .then(response => response.json())
            .then(data => {
                setOrder(data);
                setAvailableTests(data.amountOfTests - data.finishedTests);
                const testsObject = [];

                for (let i = 0; i < (data.amountOfTests - data.finishedTests); i++) {
                    testsObject.push({
                        "id": i,
                        "distributor": "",
                        "owner": "",
                        "uniqueAnimalId": "",
                        "sampleBarcodeId": "",
                        "sampleType": "Eartag",
                        "eartagId": "",
                        "breedOfSample": "JE",
                        "sex": "M",
                        "countryCode": "DEU",
                        "multipleBirth": 1,
                        "birthDate": "2024-01-01",
                        "sireRegistration": "",
                        "breedOfSire": data.level === 'basic' ? "" : "JE",
                        "damRegistrationId": "",
                        "breedOfDam": data.level === 'basic' ? "" : "JE",
                        "isProductionBull": false,
                        "serviceLevel": data.level,
                        "hasBvdTest": data.hasBvdTest,
                        "comments": "",
                    });
                }
                setTests(testsObject);
            }).catch(error => {
                console.error(error);
                setError(true);
            });
        }
    }, [order]);

    const updateTest = (id:number, field:string, value:any) => {
        const newTests = tests.map((test) => {
            if (test.id === id) {
                return {
                    ...test,
                    [field]: value
                };
            }
            return test;
        });
        setTests(newTests);
    };

    const submitForm = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        try {
            fetch(`https://app.genfarmeval.com/api/orders/${ orderId }/?${ token }`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tests)
            });
            setView('success');
        } catch (error) {
            console.error(error);
            setError(true);
        }
    };

    return (
        <div className="w-full">
            { order === null && !error && <div className="text-center text-xl my-12">Loading...</div> }
            { error && <div className="text-center text-xl my-12">There was an error. Please try again later.</div> }
            { order !== null && !error && (
                <>
                    <div className="w-full">
                        <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                            <h3 className="text-base font-semibold text-gray-900">Order #{ order.shopifyId }</h3>
                            <div className="mt-3 sm:ml-4 sm:mt-0">
                                <div></div>
                            </div>
                        </div>
                        {view === 'overview' && availableTests > 0 && (
                            <form onSubmit={submitForm}>
                                { tests.map((test, index) => <DataInputInstance key={index} test={test} updateTest={updateTest} />) }
                                <div className="overflow-hidden rounded-lg bg-white shadow my-4">
                                    <div className="px-4 py-5 sm:p-6">
                                    <input
                                        type="submit"
                                        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                        value="Submit information"
                                    />
                                    </div>
                                </div>
                            </form>
                        )}
                        {view === 'overview' && availableTests === 0 && (
                            <div className="text-center text-xl my-12">You have already completed data input.</div>
                        )}
                        {view === 'success' && (
                            <div className="text-center text-xl my-12">Thank you for submitting the data. You will receive an email with your results once your samples have been received and processed.</div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

const DataInputInstance = ({test, updateTest}:any) => {
    return (
        <div className="overflow-hidden rounded-lg bg-white shadow my-4">
                <div className="px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor={`owner[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Owner Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id={`owner[${ test.id }]`}
                                    name={`owner[${ test.id }]`}
                                    type="text"
                                    value={test.owner}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 px-2"
                                    onChange = {(e) => updateTest(test.id, "owner", e.target.value)}
                                />
                                <span className="text-xs text-gray-500 block mt-2">Name of the animal owner (Farm)</span>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`uniqueAnimalId[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Unique Animal ID
                            </label>
                            <div className="mt-2">
                                <input
                                    id={`uniqueAnimalId[${ test.id }]`}
                                    name={`uniqueAnimalId[${ test.id }]`}
                                    type="text"
                                    value={test.uniqueAnimalId}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 px-2"
                                    onChange = {(e) => updateTest(test.id, "uniqueAnimalId", e.target.value)}
                                />
                                <span className="text-xs text-gray-500 block mt-2">17-digit unique ID which consists of a 2-digit breed code (letter), 3-digit country code (letter or number) and 12-digit number (letter or number)</span>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`sampleBarcodeId[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Sample Barcode ID
                            </label>
                            <div className="mt-2">
                                <input
                                    id={`sampleBarcodeId[${ test.id }]`}
                                    name={`sampleBarcodeId[${ test.id }]`}
                                    type="text"
                                    value={test.sampleBarcodeId}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 px-2"
                                    onChange = {(e) => updateTest(test.id, "sampleBarcodeId", e.target.value)}
                                />
                                <span className="text-xs text-gray-500 block mt-2">A unique barcode number that must be recorded for sample identification on the sample tube</span>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`eartagId[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Ear Tag Number
                            </label>
                            <div className="mt-2">
                                <input
                                    id={`eartagId[${ test.id }]`}
                                    name={`eartagId[${ test.id }]`}
                                    type="text"
                                    value={test.eartagId}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 px-2"
                                    onChange = {(e) => updateTest(test.id, "eartagId", e.target.value)}
                                />
                                <span className="text-xs text-gray-500 block mt-2">12 digits (letter or numbers). If your ID is not 12 digits long, add zeroes in front of the ID to reach 12 digits</span>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`breedOfSample[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Breed of Sample
                            </label>
                            <div className="mt-2">
                                <select
                                    id={`breedOfSample[${ test.id }]`}
                                    name={`breedOfSample[${ test.id }]`}
                                    defaultValue={ test.breedOfSample }
                                    required
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm/6"
                                    onChange = {(e) => updateTest(test.id, "breedOfSample", e.target.value)}
                                >
                                    <option value="HO">Holstein</option>
                                    <option value="JE">Jersey</option>
                                    <option value="BS">Brown Swiss</option>
                                    <option value="AY">Ayrshire</option>
                                    <option value="GU">Guernsey</option>
                                    <option value="XBRED">Crossbred</option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`sex[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Sex
                            </label>
                            <div className="mt-2">
                                <select
                                    id={`sex[${ test.id }]`}
                                    name={`sex[${ test.id }]`}
                                    defaultValue={ test.sex }
                                    required
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm/6"
                                    onChange = {(e) => updateTest(test.id, "sex", e.target.value)}
                                >
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`country[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Country
                            </label>
                            <div className="mt-2">
                                <select
                                    id={`country[${ test.id }]`}
                                    name={`country[${ test.id }]`}
                                    defaultValue={ test.countryCode }
                                    required
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm/6"
                                    onChange = {(e) => updateTest(test.id, "countryCode", e.target.value)}
                                >
                                    { iso31661.map((country, index) => <option key={index} value={country.alpha3}>{country.name}</option>) }
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`multipleBirth[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Is multiple birth?
                            </label>
                            <div className="mt-2">
                                <select
                                    id={`multipleBirth[${ test.id }]`}
                                    name={`multipleBirth[${ test.id }]`}
                                    defaultValue={ test.multipleBirth }
                                    required
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm/6"
                                    onChange = {(e) => updateTest(test.id, "multipleBirth", parseInt(e.target.value))}
                                >
                                    <option value="1">Single birth</option>
                                    <option value="2">Multiple birth (twins)</option>
                                    <option value="3">Birth from embryo transfer</option>
                                </select>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor={`birthDate[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                Birth Date
                            </label>
                            <div className="mt-2">
                                <input
                                    id={`birthDate[${ test.id }]`} 
                                    name={`birthDate[${ test.id }]`} 
                                    type="date"
                                    value={moment(test.birthDate).format("YYYY-MM-DD")}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 px-2"
                                    onChange={(e) => updateTest(test.id, "birthDate", moment(e.target.value).toDate())}
                                />
                            </div>
                        </div>
                        { test.serviceLevel !== 'basic' && (
                            <>
                                <div className="sm:col-span-6">
                                    <h4 className="text-lg font-semibold">Additional Information</h4>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor={`sireRegistration[${ test.id }]`}  className="block text-sm/6 font-medium text-gray-900">
                                        Sire Registration ID
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id={`sireRegistration[${ test.id }]`}
                                            name={`sireRegistration[${ test.id }]`}
                                            type="text"
                                            value={ test.sireRegistration }
                                            required
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 px-2"
                                            onChange={(e) => updateTest(test.id, "sireRegistration", e.target.value)}
                                        />
                                        <span className="text-xs text-gray-500 block mt-2">Either 17-digit unique ID which consists of a 2-digit breed code (letter), 3-digit country code (letter or number) and 12-digit number (letter or number)OR NAAB number</span>
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor={`breedOfSire[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                        Breed of Sire
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id={`breedOfSire[${ test.id }]`} 
                                            name={`breedOfSire[${ test.id }]`} 
                                            defaultValue={ test.breedOfSire }
                                            required
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm/6"
                                            onChange={(e) => updateTest(test.id, "breedOfSire", e.target.value)}
                                        >
                                            <option value="HO">Holstein</option>
                                            <option value="JE">Jersey</option>
                                            <option value="BS">Brown Swiss</option>
                                            <option value="AY">Ayrshire</option>
                                            <option value="GU">Guernsey</option>
                                            <option value="XBRED">Crossbred</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor={`damRegistrationId[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                        Dam Registration ID
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id={`damRegistrationId[${ test.id }]`}
                                            name={`damRegistrationId[${ test.id }]`}
                                            type="text"
                                            required
                                            value={test.damRegistrationId}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 px-2"
                                            onChange={(e) => updateTest(test.id, "damRegistrationId", e.target.value)}
                                        />
                                        <span className="text-xs text-gray-500 block mt-2">17-digit unique ID which consists of a 2-digit breed code (letter), 3-digit country code (letter or number) and 12-digit number (letter or number)</span>
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor={`breedOfDam[${ test.id }]`} className="block text-sm/6 font-medium text-gray-900">
                                        Breed of Dam
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id={`breedOfDam[${ test.id }]`} 
                                            name={`breedOfDam[${ test.id }]`} 
                                            defaultValue={ test.breedOfDam }
                                            required
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm/6"
                                            onChange={(e) => updateTest(test.id, "breedOfDam", e.target.value)}
                                        >
                                            <option value="HO">Holstein</option>
                                            <option value="JE">Jersey</option>
                                            <option value="BS">Brown Swiss</option>
                                            <option value="AY">Ayrshire</option>
                                            <option value="GU">Guernsey</option>
                                            <option value="XBRED">Crossbred</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor={`isProductionBull[${ test.id }]`}  className="block text-sm/6 font-medium text-gray-900">
                                        Is production bull?
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            id={`isProductionBull[${ test.id }]`} 
                                            name={`isProductionBull[${ test.id }]`} 
                                            defaultValue={ (test.isProductionBull ? "true" : "false" ) }
                                            required
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm/6"
                                            onChange={(e) => updateTest(test.id, "isProductionBull", e.target.value === "true")}
                                        >
                                            <option value="true">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="sm:col-span-6">
                            <label htmlFor={`comment[${ test.id }]`}  className="block text-sm/6 font-medium text-gray-900">
                                Comment
                            </label>
                            <div className="mt-2">
                            <textarea
                                id={`comment[${ test.id }]`} 
                                name={`comment[${ test.id }]`} 
                                rows={4}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6 p-2"
                                defaultValue={test.comments}
                                onChange={(e) => updateTest(test.id, "comments", e.target.value)}
                            />
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Order;
