
function Home() {

  return (
    <div className="text-center w-full">
        <div className="text-center text-xl mt-12 mb-3">Please return to the shop</div>
        <a href="https://genfarmeval.com" className="mb-12 mx-auto rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Back to shop</a>
    </div>
  );
}

export default Home;
