import { BrowserRouter, Route, Routes } from "react-router";
import Home from './components/Home';
import Order from './components/Order';
import logo from './logo.gif';

function App() {
  return (
    <>
      <header className="bg-white">
        <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <span className="sr-only">Eurofins Genomics</span>
            <img
              alt=""
              src={logo}
              className="h-8 w-auto"
            />
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
          </div>
          <div className="flex flex-1 items-center justify-end gap-x-6">
            <a
              href="https://genfarmeval.com/account"
              className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Back to account
            </a>
          </div>
        </nav>
      </header>
      <main className="bg-gray-50">
        <div className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8">
          <BrowserRouter>
            <Routes>
              <Route path="/" Component={Home} />
              <Route path="orders/:orderId" Component={Order} />
            </Routes>
          </BrowserRouter>
        </div>
      </main>
      <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav aria-label="Footer" className="-mb-6 flex flex-wrap justify-center gap-x-12 gap-y-3 text-sm/6">
            <a href="https://genfarmeval.com" className="text-gray-600 hover:text-gray-900">
              Imprint
            </a>
            <a href="https://genfarmeval.com" className="text-gray-600 hover:text-gray-900">
              Data-Privacy
            </a>
        </nav>
        <p className="mt-10 text-center text-sm/6 text-gray-600">&copy; { new Date().getFullYear() } Eurofins Genomics. All rights reserved.</p>
      </div>
    </footer>
    </>
  );
}

export default App;
